import * as React from 'react';
import * as Styled from './homeGreyBackgroundStyle';
import constants from '../../utils/utils';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlhref,
} from '../../utils';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

const HomeGreyBackground = ({ sectionData }) => {
  const ParklandLogo = sectionData?.listOfImagesWithPath?.filter(
    (section) => section.type === constants.HOME.PARKLAND_LOGO
  )[0];
  const ChevronLogo = sectionData?.listOfImagesWithPath?.filter(
    (section) => section.type === constants.HOME.CHEVRON_LOGO
  )[0];
  const textData = sectionData?.listOfImagesWithPath?.filter(
    (section) => section.type === constants.HOME.TEXT_DATA
  )[0];

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2>{children}</Styled.HeaderH2>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.LinkStyle
          href={
            isExternalUrlhref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                  process.env.GATSBY_DOMAIN_URL + node?.data?.uri
                )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.LinkStyle>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.DefaultGrayContainer>
      <Styled.HomeGrayInnerContainer>
        <Styled.ParklandLogoStyle
          src={ParklandLogo?.image?.file?.url}
          alt={ParklandLogo?.image?.file?.fileName}
        />
        <Styled.ChevronLogoStyle
          src={ChevronLogo?.image?.file?.url}
          alt={ChevronLogo?.image?.file?.fileName}
        />
        {textData?.richText &&
          documentToReactComponents(
            JSON.parse(textData?.richText?.raw),
            optionsMainStyle
          )}
        <div style={{ clear: 'both' }}></div>
      </Styled.HomeGrayInnerContainer>
    </Styled.DefaultGrayContainer>
  );
};
export default HomeGreyBackground;
