import styled from 'styled-components';

export const DefaultGrayContainer = styled.div`
  background: #ececec;
  color: #666;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 22px;
  }
`;

export const HomeGrayInnerContainer = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding: 65px 0;
  @media (max-width: 1199px) {
    width: 960px;
  }
  @media (max-width: 991px) {
    width: 768px;
  }
  @media (max-width: 767px) {
    width: 100%;
    text-align: center;
    padding: 30px;
  }
`;

export const ParklandLogoStyle = styled.img`
  float: left;
  width: auto;
  height: 216px;
  margin-right: 120px;
  @media (max-width: 1199px) {
    margin-right: 60px;
  }
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    float: left;
    width: 40%;
    height: auto;
    margin: 0;
  }
`;

export const ChevronLogoStyle = styled.img`
  float: right;
  width: auto;
  height: 216px;
  margin-left: 120px;
  @media (max-width: 1199px) {
    margin-left: 60px;
  }
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    float: right;
    width: 40%;
    height: auto;
    margin: 0;
  }
`;

export const HeaderH2 = styled.h2`
  margin: 0 0 25px 0;
  font-family: 'Gotham-Light', arial, sans-serif;
  font-size: 35px;
  line-height: 40px;
  text-align: center;
  color: #0069aa;
  @media (max-width: 1199px) {
    font-size: 28px;
    line-height: 35px;
  }
  @media (max-width: 991px) {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    float: left;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 40px;
  }
`;

export const ParaText = styled.p`
  margin: 0;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #656565;
  @media (max-width: 767px) {
    float: left;
    width: 100%;
  }
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: #1fb25a;
  transition: color 0.15s ease-out;
  :hover {
    text-decoration: none;
    color: #0397d6;
  }
`;
